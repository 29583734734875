<template>
  <div class="talentsData">
    <div class="bottomCard ly-container__bg">
      <div class="ly_operation" style="margin-bottom: 10px">
        <div class="left">
          <!-- 添加案例 -->
          <el-button @click="addItem" type="primary" icon="el-icon-plus">新建根标签</el-button>
        </div>
        <!-- 搜索框 -->
        <el-input
          placeholder="根据标签名称进行搜索"
          v-model="queryInfo.name"
          style="width: 470px"
          @change="getSchemes1"
        >
          <template slot="append">
            <div @click="getSchemes1" style="cursor: pointer">搜索</div>
          </template>
        </el-input>
      </div>
      <!-- 表格数据 -->
      <el-table
        class="ly_table"
        :data="dataData"
        :cell-style="{ 'text-align': 'center' }"
        :header-cell-style="{
          background: '#F5F6F7',
          color: '#363E4D',
          'text-align': 'center',
        }"
      >
        <el-table-column prop="codeName" label="标签名" align="center" />
        <el-table-column prop="typeName" label="标签类型"></el-table-column>
        <el-table-column prop="isActivation" label="状态">
          <template v-slot="{ row }">
            <el-switch
              v-model="row.isActivation"
              active-color="#13ce66"
              inactive-color="#c6c6c6"
              active-value="0"
              inactive-value="1"
              @change="btnClick(row)"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间"></el-table-column>
        <el-table-column prop="updateName" label="更新人"></el-table-column>
        <el-table-column prop="updateTime" label="更新时间"></el-table-column>
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <button-table
              @buttonClick="editTalent(scope.row)"
              content="编辑"
              icon="jr-iconjr-icon-edit"
            />
            <button-table
              @buttonClick="deleteTalent(scope.row.id)"
              content="删除"
              icon="jr-iconjr-icon-delect"
            />
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        :current-page="queryInfo.pageNumber"
        :page-sizes="[3, 5, 10]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
      <el-dialog
        :title="addEditValue ? '编辑用户' : '添加用户'"
        :visible.sync="dialogVisible"
        width="30%"
        @close="handleClose"
        center
      >
        <el-form label-width="120px">
          <el-form-item label="标签名称：">
            <el-input v-model="userData.codeName"></el-input>
          </el-form-item>
          <el-form-item label="标签类型：">
            <el-select v-model="userData.codeType" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否开启标签：">
            <el-switch
              v-model="userData.isActivation"
              active-color="#13ce66"
              inactive-color="#c6c6c6"
              active-value="0"
              inactive-value="1"
            />
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="addCode">确 定</el-button>
          <el-button @click="dialogVisible = false">取 消</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script src="../../assets/font/iconfont.js"></script>
<script>
import ButtonTable from '../../../components/ButtonTable.vue'
import {
  addeditDataCode,
  getCodeComboBox,
  getDataCode,
  deleteCodeMasert,
} from '@/api/system'
import { getDicRadioButton } from '@/api/common.js'
export default {
  components: {
    ButtonTable,
  },
  created() {
    this.getTechnologys()
    this.getCodeComboBoxs()
  },
  data() {
    return {
      // 查询搜索数据
      // 表格数据
      queryInfo: {
        pageSize: 10,
        pageNum: 1,
        order: 1,
      },
      dialogVisible: false,
      dataData: [],
      total: 0,
      // 删除
      deleteTalentData: [],
      userData: {
        isActivation: null,
        typeName: '',
        codeType: '',
        codeName: '',
      },
      addEditValue: false,
      options: [],
    }
  },
  methods: {
    async getCodeComboBoxs() {
      const res = await getCodeComboBox()
      this.options = res.data.map((item) => {
        return {
          label: item.typeName,
          value: item.codeType,
        }
      })
    },
    // 浏览人才数据
    getSchemes1() {
      let name = this.queryInfo.name
      this.queryInfo = {
        name: name,
        pageSize: 10,
        pageNum: 1,
        order: 1,
      }
      this.getTechnologys()
    },
    async getTechnologys() {
      const res = await getDataCode(this.queryInfo)
      if (res.code === 200) {
        this.dataData = res.data.list
        this.total = res.data.total
      }
    },
    // 分页
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.getTechnologys()
    },
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage
      this.getTechnologys()
    },
    // 跳转编辑页面
    editTalent(data) {
      this.dialogVisible = true
      this.addEditValue = true
      this.userData = data
    },
    // 单个删除
    deleteTalent(id) {
      this.deleteTalentData[0] = id
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示').then(
        async () => {
          const res = await deleteCodeMasert({ id: this.deleteTalentData })
          if (res.code == 200) {
            this.getTechnologys()
            this.deleteTalentData = []
            this.$message.success('删除成功')
          }
        }
      )
    },
    addItem() {
      this.dialogVisible = true
      this.addEditValue = false
    },
    async addCode() {
      const res = await addeditDataCode(this.userData)
      if (res.code == 200) {
        if (this.addEditValue) {
          this.$message.success('编辑成功')
        } else {
          this.$message.success('添加成功')
        }
        const { data: res1 } = await getDicRadioButton({
          code: this.userData.codeType,
        })
        sessionStorage.setItem('001', JSON.stringify(res1))
        this.getTechnologys()
        this.dialogVisible = false
      }
    },
    formatString(val) {
      if (!val) {
        return []
      } else {
        return val.split(',')
      }
    },
    onChange() {
      this.getTechnologys()
    },
    async btnClick(data) {
      const res = await addeditDataCode(data)
      if (res.code == 200) {
        this.$message.success('修改成功')
        this.getTechnologys()
        const { data: res1 } = await getDicRadioButton({
          code: data.codeType,
        })
        sessionStorage.setItem(data.codeType, JSON.stringify(res1))
      }
    },
    handleClose() {
      this.userData = {
        isActivation: null,
        typeName: '',
        codeType: '',
        codeName: '',
      }
    },
  },
}
</script>

<style lang="less" scoped>
/deep/ .el-card__body {
  padding: 10px;
}
// 下半el-card部分
.left {
  display: flex;
  align-items: center;
  // position: relative;
  /deep/ .el-button {
    padding: 10px 10px;
  }
}
/deep/ .el-input-group__append {
  background: #1e9fff;
  color: #fff;
  border: 1px solid #1e9fff;
}
/deep/ .el-dialog__header {
  text-align: center;
  font-weight: 700;
}
/deep/ .el-dialog {
  min-height: 100px;
  overflow: auto;
}
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
</style>
