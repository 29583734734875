<template>
  <div class="talentsData">
    <div class="bottomCard ly-container__bg">
      <div class="ly_operation" style="margin-bottom: 10px">
        <div class="left">
          <!-- 添加案例 -->
          <el-button @click="addItem" type="primary" icon="el-icon-plus">新建根标签</el-button>
        </div>
        <!-- 搜索框 -->
        <el-input
          placeholder="根据标签名称进行搜索"
          v-model="queryInfo.dim"
          style="width: 470px"
          @change="getSchemes1"
        >
          <template slot="append">
            <div @click="getSchemes1" style="cursor: pointer">搜索</div>
          </template>
        </el-input>
      </div>
      <!-- 表格数据 -->
      <el-table
        class="ly_table"
        :data="dataData"
        :cell-style="{ 'text-align': 'center' }"
        :header-cell-style="{
          background: '#F5F6F7',
          color: '#363E4D',
          'text-align': 'center',
        }"
        @selection-change="handleSelectionChange"
      >
        <el-table-column prop="id" label="页面名称" align="center" />
        <el-table-column prop="techName" label="根标签"></el-table-column>
        <el-table-column prop="screen" label="标签状态">
          <template v-slot="{ row }">
            <el-switch
              v-model="row.screen"
              active-color="#13ce66"
              inactive-color="#c6c6c6"
              :active-value="1"
              :inactive-value="0"
              @change="btnClick(row)"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="abbreviation" label="创建人"></el-table-column>
        <el-table-column prop="knowTech.techName" label="创建时间"></el-table-column>
        <el-table-column prop="knowTech.techName" label="更新人"></el-table-column>
        <el-table-column prop="knowTech.techName" label="更新时间"></el-table-column>
        <el-table-column label="操作" width="150">
          <template slot-scope="scope">
            <button-table @buttonClick="editOption(scope.row)" content="设置" icon="el-icon-s-tools" />
            <button-table
              @buttonClick="editTalent(scope.row)"
              content="编辑"
              icon="jr-iconjr-icon-edit"
            />
            <button-table
              @buttonClick="deleteTalent(scope.row.id)"
              content="删除"
              icon="jr-iconjr-icon-delect"
            />
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        :current-page="queryInfo.pageNumber"
        :page-sizes="[3, 5, 10]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
      <el-dialog
        title="新增页面标签"
        :visible.sync="dialogVisible"
        width="30%"
        @close="handleClose"
        center
      >
        <el-form label-width="100px">
          <el-form-item label="页面名称：">
            <el-input></el-input>
          </el-form-item>
          <el-form-item label="页面标签：">
            <el-tag
              :key="tag"
              v-for="tag in dynamicTags"
              closable
              :disable-transitions="false"
              @close="handleClose1(tag)"
            >{{ tag }}</el-tag>
            <el-input
              class="input-new-tag"
              v-if="inputVisible"
              v-model="inputValue"
              ref="saveTagInput"
              size="small"
              @keyup.enter.native="handleInputConfirm"
              @blur="handleInputConfirm"
            ></el-input>
            <el-button v-else class="button-new-tag" size="small" @click="showInput">+ New Tag</el-button>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
          <el-button @click="dialogVisible = false">取 消</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script src="../../assets/font/iconfont.js"></script>
<script>
import DicTagButton from '@/components/DicTagButton.vue'
import UploadFileTalent from '@/components/UploadFileTalent.vue'
import ButtonTable from '../../../components/ButtonTable.vue'
import { getKnowTechList } from '@/api/common'
import {
  getTechnology,
  deleteTechnology,
  editTechnology,
} from '@/api/technology.js'
import DicRadioButton from '@/components/DicRadioButton.vue'
import DicCheckboxGroup from '@/components/DicCheckboxGroup.vue'
export default {
  components: {
    ButtonTable,
    DicRadioButton,
    DicTagButton,
    DicCheckboxGroup,
    UploadFileTalent,
  },
  created() {
    this.getTechnologys()
  },
  data() {
    return {
      // 查询搜索数据
      // 表格数据
      queryInfo: {
        pageSize: 10,
        pageNum: 1,
        order: 1,
      },
      dialogVisible: false,
      dataData: [],
      total: 0,
      // 搜索
      input2: '',
      // 删除
      deleteTalentData: [],
      multipleSelection: [],
      dynamicTags: ['标签一', '标签二', '标签三'],
      inputVisible: false,
      inputValue: '',
    }
  },
  methods: {
    // 按上传时间显示
    async uploadTime() {
      if (this.queryInfo.order == 1) {
        this.queryInfo.order = 0
        this.getTechnologys()
      } else {
        this.queryInfo.order = 1
        this.getTechnologys()
      }
    },
    // 浏览人才数据
    getSchemes1() {
      let dim = this.queryInfo.dim
      this.queryInfo = {
        dim: dim,
        pageSize: 10,
        pageNum: 1,
        order: 1,
      }
      this.getTechnologys()
    },
    async getTechnologys() {
      const res = await getTechnology(this.queryInfo)
      if (res.code === 200) {
        this.dataData = res.data.list
        this.total = res.data.total
        this.dataData.forEach((el) => {
          if (el.integrity) {
            el.integrity = Number(el.integrity.slice(0, -1))
          } else {
            el.integrity = 0
          }
        })
      }
    },
    // 按姓名搜索
    // 分页
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.getTechnologys()
    },
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage
      this.getTechnologys()
    },
    // 选中表格数据并赋值id选项
    handleSelectionChange(val) {
      this.multipleSelection = val.map((item) => {
        return item.id
      })
    },
    // 跳转编辑页面
    editTalent(data) {
      this.dialogVisible = true
    },
    // 单个删除
    deleteTalent(id) {
      this.deleteTalentData[0] = id
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示').then(
        async () => {
          const res = await deleteTechnology(this.deleteTalentData)
          if (res.code == 200) {
            this.getTechnologys()
            this.deleteTalentData = []
            this.$message.success('删除成功')
          }
        }
      )
    },
    // // 批量删除
    multDelete() {
      if (this.multipleSelection.length > 1) {
        this.$confirm('此操作将永久删除批量文件, 是否继续?', '提示').then(
          async () => {
            const res = await deleteTechnology(this.multipleSelection)
            if (res.code == 200) {
              this.getTechnologys()
              this.multipleSelection = []
              this.$message.success('删除成功')
            }
          }
        )
      } else {
        this.$message.error('批量删除必须大于一条')
      }
    },
    addItem() {
      this.dialogVisible = true
    },
    formatString(val) {
      if (!val) {
        return []
      } else {
        return val.split(',')
      }
    },
    onChange() {
      this.getTechnologys()
    },
    async btnClick(data) {
      const res = await editTechnology(data)
      this.$message.success('修改成功')
      this.getTechnologys()
      const res1 = await getKnowTechList()
      res1.data = JSON.parse(
        JSON.stringify(res1.data).replace(/techName/g, 'codeName')
      )
      sessionStorage.setItem('tech', JSON.stringify(res1.data))
    },
    handleClose1(tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1)
    },

    showInput() {
      this.inputVisible = true
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus()
      })
    },

    handleInputConfirm() {
      let inputValue = this.inputValue
      if (inputValue) {
        this.dynamicTags.push(inputValue)
      }
      this.inputVisible = false
      this.inputValue = ''
    },
    handleClose() {},
  },
}
</script>

<style lang="less" scoped>
/deep/ .el-card__body {
  padding: 10px;
}
// 下半el-card部分
.left {
  display: flex;
  align-items: center;
  // position: relative;
  /deep/ .el-button {
    padding: 10px 10px;
  }
}
/deep/ .el-input-group__append {
  background: #1e9fff;
  color: #fff;
  border: 1px solid #1e9fff;
}
/deep/ .el-dialog__header {
  text-align: center;
  font-weight: 700;
}
/deep/ .el-dialog {
  min-height: 100px;
  overflow: auto;
  width: 50% !important;
}
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
/deep/ .el-icon-s-tools {
  font-size: 16px;
}
</style>
