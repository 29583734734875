<template>
  <div>
    <div class="mainIndex">
      <el-card>
        <el-tabs v-model="activeName">
          <!-- <el-tab-pane label="页面基本信息" name="first">
            <person-message
              ref="personMessage"
            ></person-message>
          </el-tab-pane>-->
          <el-tab-pane label="标签基本信息" name="first">
            <work-history ref="workHistory"></work-history>
          </el-tab-pane>
        </el-tabs>
      </el-card>
    </div>
  </div>
</template>
<script>
import { getSchemeOne, addScheme } from '@/api/scheme'
import PersonMessage from './components/page.vue'
import WorkHistory from './components/label.vue'
export default {
  name: 'editTalentXS',
  components: {
    PersonMessage,
    WorkHistory,
  },
  computed: {},
  created() {},
  data() {
    return {
      activeName: 'first',
    }
  },
  methods: {},
}
</script>

<style lang="less" scoped>
.company {
  background: #fff;
  padding: 20px 20px;
  margin-bottom: 20px;
}
/deep/ .el-tabs__content {
  overflow: auto;
}
/deep/ .el-card__body {
  padding: 0px 20px 0px 20px;
}
</style>
