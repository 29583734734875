import request from '@/utils/request'

//方案一览
export function getScheme(data) {
  return request({
    url: '/system/solution/getSolutionList',
    method: 'post',
    data
  })
}

// 方案删除
export function deleteScheme(data) {
  return request({
    url: '/system/solution/deleteSolutionById',
    method: 'post',
    data
  })
}

// 单个方案查询
export function getSchemeOne(data) {
  return request({
    url: '/system/solution/getSolutionOne',
    method: 'get',
    params: data
  })
}

// 方案新增
export function addScheme(data) {
  return request({
    url: '/system/solution/insertSolution',
    method: 'post',
    data
  })
}

// 方案修改
export function editScheme(data) {
  return request({
    url: '/system/solution/updateSolution',
    method: 'post',
    data
  })
}

// 企业智能查询
export function companySearch(data) {
  return request({
    url: '/system/solution/getByCompanyName',
    method: 'post',
    data
  })
}

// 成功案例智能查询
export function successSearch(data) {
  return request({
    url: '/system/solution/getPlanTitle',
    method: 'get',
    params: data
  })
}

// 功能模块单个查询
export function solutionModule(data) {
  return request({
    url: '/system/solutionModule/getSolutionBySolutionId',
    method: 'get',
    params: data
  })
}

// 功能模块修改
export function editSolutionModule(data) {
  return request({
    url: '/system/solutionModule/updateSolutionModule',
    method: 'post',
    data
  })
}

// 功能模块修改
export function editsolutionModule(data) {
  return request({
    url: '/system/solutionModule/updateSolutionModule',
    method: 'post',
    data
  })
}

// 附件的查询
export function getFile(data) {
  return request({
    url: '/system/solutionAppendix/getFileList',
    method: 'get',
    params: data
  })
}

// 附件的添加
export function addFile(data) {
  return request({
    url: '/system/solutionAppendix/addSolutionFile',
    method: 'post',
    data
  })
}

// 附件的删除
export function deleteFile(data) {
  return request({
    url: '/system/solutionAppendix/deleteFile',
    method: 'get',
    params: data
  })
}

// 附件上传
export function uploadScheme(data) {
  return request({
    url: '/system/solution/importSolution',
    method: 'post',
    data
  })
}
